@import '@cityssm/bulma-sticky-table/bulma-with-sticky-table';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropdown-content {
  max-height: 50vh;
  width: 20em;
  overflow: auto;
}

.dropdown-trigger button {
  width: 20em;
  display: flex;
  justify-content: space-between;
}

.monospace {
  font-family: "andale mono", "Monaco", "Courier New";
}
