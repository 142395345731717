.train-display {
  white-space: nowrap;
  font-weight: 900;
  font-size: 0.75em;
  text-align: center !important;
}

input {
  margin-right: 0.3rem;
}

.am-pm-display {
  white-space: nowrap;
  font-weight: 900;
  font-size: 1em;
  padding-top: 0.18rem !important;
  padding-bottom: 0.18rem !important;
  text-align: center !important;
}

th p {
  font-weight: 300 !important;
}

th {
  vertical-align: middle !important;
}

tbody td {
  font-size: 0.9em;
  font-family: "Monaco", monospace;
  text-align: center !important;
  vertical-align: middle !important;
  width: 4.7em !important;
}

.route-description {
  align-self: center;
  font-size: 1.25em;
  font-weight: 400;
}

div .table-container {
  width: 95%;
  max-height: 80vh;
  border-radius: 10px;
  overflow: scroll;
}

.train-line-entry {
  align-items: center;
  height: 30px;
  font-size: 16px;
}

.timetable-root {
  display: flex;
  flex-direction: column;
  align-items: center
}
